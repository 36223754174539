import React from 'react'
import { Link, graphql } from 'gatsby'

import PagesStyles from './pages.module.css'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { MDXRenderer } from 'gatsby-plugin-mdx'

class PageTemplate extends React.Component {
  render() {
    const page = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const imageURL = page.frontmatter.image.publicURL
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={page.frontmatter.title} description={page.frontmatter.overview} image={imageURL}/>
        <div className={PagesStyles.cover} style={{backgroundImage: `url(${imageURL})`}} />
        <div className={PagesStyles.container}>
            <div className={PagesStyles.coverContent}>
                <h1 style={{fontFamily: `'Gill sans', sans-serif`, fontWeight: 500}}>{page.frontmatter.title}</h1>
                <h2>{page.frontmatter.subtitle}</h2>
            </div>
            <MDXRenderer>{page.body}</MDXRenderer>
        </div>
        <div
          style={{
            marginBottom: 8,
          }}
        />
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
        siteMetadata {
          title
        }
      }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        title
        subtitle
        overview
        image {
            publicURL
        }
      }
    }
  }
`
